<template>
    <div>
        <div class="position-fixed" style="width: 100vw; left: 0; right: 0; z-index: 1">
            <footer-bar v-if="!$auth.loggedIn" style="height: 75px"></footer-bar>
        </div>

        <div class="content">
            <div class="row mb-4">
                <div class="col-lg-7 d-none d-lg-block" style="margin-top: 74px">
                    <h1 class="text-vanitas d-none d-lg-block my-4">{{ $t('Iscriviti a Confestetica') }}</h1>
                    <h3 class="acumin text-dark-grey font-size-extra mt-4">{{ $t('L\'unica associazione nazionale maggiormente rappresentativa dell\'estetista.') }}</h3>
                    <h3 class="acumin mt-4 text-dark-grey font-size-extra">
                        {{ $t('Fondata nel') }} <strong>2007</strong> con <nuxt-link to="/chi-siamo/atto-costitutivo">atto pubblico.</nuxt-link><br/>
                      {{ $t('Ad oggi conta') }} <nuxt-link to="/estetiste">{{ (users.usersCount + 9000).toLocaleString() }}</nuxt-link> {{ $t('estetiste iscritte e certificate e ') }}<nuxt-link to="/estetiste">{{ (accounts.accountsCount).toLocaleString() }}</nuxt-link> {{ $t(' centri estetici.') }}<br>                    </h3>
                    <img src="~/assets/images/img_home.png" class="register-image d-none d-lg-block" />
                </div>
                <div class="col-lg-5 sign-up-container" style="margin-top: 115px">
<!--                    <v-facebook-login
                            v-if="!mobileMode"
                            :app-id="facebookAppId"
                            class="mt-5 w-100 text-center"
                            :token-style="{'margin': 0}"
                            :login-options="{scope: 'email'}"
                            @login="facebookLogin"
                    >
                        <template slot="login">
                            {{ $t('Accedi con Facebook') }}
                        </template>
                    </v-facebook-login>

                    <div class="my-2 font-size-sm text-grey d-flex align-items-center justify-content-center">
                        <div class="divider mr-2"></div>
                        {{ $t('oppure') }}
                        <div class="divider ml-2"></div>
                    </div>-->

                    <h4 class="acumin d-none d-lg-block">{{ $t('Crea il tuo nuovo account professionale') }}</h4>

                    <h2 class="vanitas d-block d-lg-none">{{ $t('Entra a far parte della nostra community')}}</h2>

                    <div class="mt-3">
                        <form @submit.prevent="submit" class="login-form">
                            <div class="row">
                                <div class="col">
                                    <input class="form-control form-control-lg has-label" name="first_name" :data-vv-as="$t('Nome')" v-validate="'required'" :placeholder="$t('Nome')" autocomplete="off" v-model="first_name" />
                                    <div class="form-error text-danger" v-if="errors.has('first_name')">{{ errors.first('first_name') }}</div>
                                </div>
                                <div class="col d-none d-lg-block">
                                    <input class="form-control form-control-lg has-label" name="last_name" :data-vv-as="$t('Cognome')" v-validate="'required'" :placeholder="$t('Cognome')" autocomplete="off" v-model="last_name" />
                                    <div class="form-error text-danger" v-if="errors.has('last_name')">{{ errors.first('last_name') }}</div>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col">
                                    <input class="form-control form-control-lg has-label" name="email" :data-vv-as="$t('E-mail')" v-validate="'required|email'" :placeholder="$t('E-mail')" autocomplete="off" v-model="email" />
                                    <div class="form-error text-danger" v-if="errors.has('email')">{{ errors.first('email') }}</div>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col">
                                    <input v-input-password class="form-control form-control-lg has-label" type="password" :data-vv-as="$t('Password')" name="password" v-validate="'required'" :placeholder="$t('Password')" autocomplete="off" v-model="password" />
                                    <div class="form-error text-danger" v-if="errors.has('password')">{{ errors.first('password') }}</div>
                                </div>
                            </div>

                            <div class="row mt-3 d-none d-lg-flex">
                                <div class="col">
                                    <h4>{{ $t('Data di nascita') }}</h4>
                                    <div class="row">
                                        <div class="col">
                                            <select class="form-control" v-model="day">
                                                <option v-for="dayNumber in 31" :value="dayNumber" :key="dayNumber">{{ dayNumber }}</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <select class="form-control" v-model="month">
                                                <option v-for="monthNumber in 12" :value="monthNumber" :key="monthNumber">{{ monthNumber | monthName }}</option>
                                            </select>
                                        </div>
                                        <div class="col">
                                            <select class="form-control" v-model="year">
                                                <option v-for="year in years" :value="year" :key="year">{{ year }}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-2 d-none d-lg-flex">
                                <div class="col">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="gender" value="male" v-model="gender" />
                                        <label class="form-check-label">
                                            {{ $t('Uomo') }}
                                        </label>
                                    </div>
                                </div>
                                <div class="col">
                                    <div class="form-check">
                                        <input class="form-check-input" type="radio" name="gender" value="female" v-model="gender" />
                                        <label class="form-check-label">
                                            {{ $t('Donna') }}
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="text-grey d-block my-4">
                                <small class="d-none d-lg-block font-weight-bold">Cliccando su Iscriviti, accetti le nostre <a href="https://www.iubenda.com/termini-e-condizioni/62789847">Condizioni</a>. Scopri in che modo raccogliamo ed usiamo i tuoi dati nella nostra <a href="https://www.iubenda.com/privacy-policy/62789847">Normativa sui dati</a> e in che modo usiamo cookie e tecnologie simili nella nostra <a href="https://www.iubenda.com/privacy-policy/62789847/cookie-policy">Normativa sui cookie</a>. Potresti ricevere notifiche tramite SMS da noi, ma puoi dissattivarle in qualsiasi momento.</small>
                            </div>

                            <div class="row mt-3">
                                <div class="col">
                                    <button class="btn btn-brand btn-lg" @click.prevent="submit" :disabled="processing">
                                        <span v-if="!processing">{{ $t('Iscriviti') }}</span>
                                        <span v-else><i class="fa fa-spin fa-spinner"></i> {{ $t('Iscrizione in corso...') }}</span>
                                    </button>
                                </div>
                                <!--<button class="btn btn-facebook btn-lg ml-2" @click.prevent="submit" :disabled="processing">-->
                                <!--&lt;!&ndash;<img src="/images/fb-icon.png" />&ndash;&gt;-->
                                <!--<i class="fa fa-facebook-square"></i>-->
                                <!--{{ $t('Iscriviti con Facebook') }}-->
                                <!--</button>-->
                            </div>
                        </form>

                        <div class="text-center d-block d-lg-none mt-2">
                            {{ $t('Hai già un account?')}}<nuxt-link to="/login"> {{ $t('Log in.')}}</nuxt-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapActions, mapState } from 'vuex'
    import { mapFields } from 'vuex-map-fields'
    import _ from 'lodash'
    import Register from "~/components/Register"
    import FooterBar from '~/components/Footer'

    export default {

        components: {
          Register,
          FooterBar
        },

        data() {
            let today = this.$moment().subtract(18, 'years');

            return {
                day: today.date(),
                month: today.month() + 1,
                year: today.year(),
                facebookAppId: process.env.FACEBOOK_CLIENT_ID
            }
        },

        watch: {
            birthDate() {
                this.setBirthDate(this.birthDate);
            }
        },

        computed: {
            ...mapState({
                'registration': 'register',
                'accounts': 'accounts',
                'mobileMode': 'mobileMode',
                'users' : 'users'
            }),
            ...mapFields('register', [
                'data.first_name',
                'data.last_name',
                'data.email',
                'data.password',
                'data.password_confirmation',
                'data.gender',
                'processing'
            ]),
            birthDate() {
                return this.year + '-' + this.month + '-' + this.day;
            },
            years() {
                return _.rangeRight(1905, this.$moment().year())
            }
        },

        methods: {
            ...mapMutations({
                'setBirthDate': 'register/SET_BIRTH_DATE'
            }),
            ...mapActions({
                'processRegistration': 'register/processRegistration',
                'login': 'user/login'
            }),
            facebookLogin(payload) {

                let token = payload && payload.authResponse ? payload.authResponse.accessToken : null

                if (token) {
                    this.$nuxt.$loading.start()
                    this.$axios.post('/api/login-social', {
                        social: 'facebook',
                        token: token,
                        referrer_code: this.registration.data.referrer_code,
                        registered_from: this.registration.data.registered_from,
                    })
                        .then((response) => {
                            this.$nuxt.$loading.finish()
                            this.$auth.setUserToken(response.data.access_token)
                                .then(() => {
                                    this.$router.push('/dashboard');
                                })
                        }).finally(() => {
                        this.$nuxt.$loading.finish()
                    })
                }
            },
            submit() {

                let _self = this;

                this.$validator.validate().then(result => {

                    if (!result) return;

                    this.processing = true;

                    this.processRegistration()
                        .then(() => {

                            this.login({
                                username: this.email,
                                password: this.password
                            })
                                .then(() => {

                                    // this.$store.dispatch('authenticateEchoChannels');

                                    this.$notify({
                                        group: 'messages',
                                        type: 'success',
                                        duration: 10000,
                                        title: 'Registrazione effettuata correttamente, a breve verrai reindirizzato al tuo profilo.'
                                    });

                                    setTimeout(() => {
                                        _self.$router.push('/aggiorna-profilo')
                                    }, 2000)
                                })
                        })
                        .catch(() => {

                            this.processing = false;
                        })
                });
            }
        },

        mounted () {
            this.setBirthDate(this.birthDate)
        }
    }
</script>

<style lang="scss" scoped>
    .register-image {
        max-width: 100%;
        left: 0;
        right: 0;
    }

    .divider {
        width: 15%;
        margin-top: 3px;
        border-top: 1px solid #8C8B8C;
    }
</style>
