<template>
    <div class="content">
        <desktop-registration class="d-none d-lg-block"></desktop-registration>
        <mobile-registration class="d-block d-lg-none"></mobile-registration>
    </div>
</template>

<script>

    import { mapMutations } from 'vuex';

    import DesktopRegistration from "~/components/Register/Desktop";
    import MobileRegistration from "~/components/Register/Mobile";

    export default {

        components: {
            DesktopRegistration,
            MobileRegistration
        },

        methods: {
            ...mapMutations({
                'setReferrerCode': 'register/SET_REFERRER_CODE'
            })
        },

        created () {

            if (this.$route.query.ref) {
                this.setReferrerCode(this.$route.query.ref)
            }
        }
    }
</script>